import { ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';

interface PasswordRequirement {
  key: string;
  met: boolean;
}

export function customPasswordValidator(): ValidatorFn {
  const minimumAdditionalRequirementsToBeMet = 3;
  const passwordMinimumLength = 8;
  
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const mandatoryRequirements: PasswordRequirement[] = [
      { key: 'password-min-length', met: value.length >= passwordMinimumLength }
    ];

    const additionalRequirements: PasswordRequirement[] = [
      { key: 'password-lowercase', met: /[a-z]/.test(value) },
      { key: 'password-uppercase', met: /[A-Z]/.test(value) },
      { key: 'password-number', met: /[0-9]/.test(value) },
      { key: 'password-special', met: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value) }
    ];

    const allRequirements = mandatoryRequirements.concat(additionalRequirements);
    const metMandatoryConditions = mandatoryRequirements.filter(req => req.met).length;
    const metAdditionalConditions = additionalRequirements.filter(req => req.met).length;

    if (metMandatoryConditions < mandatoryRequirements.length || 
        metAdditionalConditions < minimumAdditionalRequirementsToBeMet) {
      return {
        passwordRequirements: {
          requirements: allRequirements,
          requiredConditions: minimumAdditionalRequirementsToBeMet,
          metConditions: metAdditionalConditions
        }
      };
    }

    return null;
  };
} 