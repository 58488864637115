import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'visibilityIcon',
  pure: true
})
export class VisibilityIconPipe implements PipeTransform {
  transform(isHidden: boolean): string {
    return isHidden ? 'fa-eye' : 'fa-eye-slash';
  }
} 