import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { UserSignupService } from './usersingup.service';
import { of } from 'rxjs';
import { getTicketInfoResponse } from './getMemberTicketInfo';
import { postCreateContractorRequest } from './postCreateContractorRequest';
import { userRegistrationWizard } from './userRegistrationWizard';
import { language } from '../interface/language';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MustMatch } from '../shared/validator/mustMatchValidator';
import { BaseTranslateComponent } from '../shared/base-translate/base-translate.component';
import { LocalStorageService } from '../service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { customPasswordValidator } from '../shared/validator/custom-password.validator';


interface PasswordRequirement {
  key: string;
  met: boolean;
}

@Component({
  selector: 'app-usersignup',
  templateUrl: './usersignup.component.html',
  styleUrls: ['./usersignup.component.sass']
})
export class UsersignupComponent extends BaseTranslateComponent implements OnInit {

  public activationId: string;

  public memberTicketInfo: getTicketInfoResponse;

  initUserRegistrationWizardModel: userRegistrationWizard = {
    activationId: '',
    email: '',
    firstName: '',
    lastName: '',
    vatNumber: '',
    confirmPassword: '',
    password: '',
    preferredLanguage: null
  }

  userRegistrationWizardModel: userRegistrationWizard = { ... this.initUserRegistrationWizardModel };

  firstUserSignupFormGroup: FormGroup;

  hidePassword = true;
  hideConfirmPassword = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: UserSignupService,
    private formBuilder: FormBuilder,
    protected storage: LocalStorageService,
    protected translate: TranslateService
  ) {
    super(storage,translate);
  }


  languages: language[] = [
    {value: '1-dutch', viewValue: 'Dutch'},
    {value: '2-french', viewValue: 'French'},
    {value: '3-english', viewValue: 'English'},
  ];


  ngOnInit() {

  this.firstUserSignupFormGroup = this.formBuilder.group({
    email: new FormControl({value: null, disabled: true},[Validators.required, Validators.email]),
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    vatNumber: new FormControl({value: null, disabled: true},[Validators.required]),
    preferredLanguage: ['1-dutch', Validators.required],
    password: ['', [
      Validators.required,
      customPasswordValidator()
    ]],
    confirmPassword: ['', [
      Validators.required,
      customPasswordValidator()
    ]],
    activationId: new FormControl({value: null, hidden: true},[Validators.required])
}, {
    validator: MustMatch('password', 'confirmPassword')
});

    this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        of(params.get('id'))
      )
    ).subscribe((d) => {
      
      this.activationId = <string>d;

      this.postInit();
    });

    this.firstUserSignupFormGroup.get('preferredLanguage').valueChanges.subscribe(val => {
      const shortLanguage = this.toShortLanguage(val);

      this.storage.setLanguage(shortLanguage);
      this.translate.use(shortLanguage);
    });
  }

  private toShortLanguage(longLanguage: string) : string {
    if(longLanguage === "1-dutch") {
      return "nl"
    }

    if(longLanguage === "2-french") {
      return "fr"
    }

    if(longLanguage === "3-english") {
      return "en"
    }

    if(longLanguage === "4-german") {
      return "de"
    }

    return "nl"
  }

  postInit() {
    this.service.getTicketInfo(this.activationId).subscribe( r => 
      {
        this.memberTicketInfo = r;

        this.firstUserSignupFormGroup.patchValue({email: this.memberTicketInfo.email});

        this.firstUserSignupFormGroup.patchValue({preferredLanguage: this.memberTicketInfo.language});
        
        this.firstUserSignupFormGroup.patchValue({activationId: this.activationId});
        
        this.firstUserSignupFormGroup.patchValue({vatNumber: this.memberTicketInfo.vatNumber});

        this.firstUserSignupFormGroup.patchValue({activationId: this.memberTicketInfo.activationId});
            
      },
      error => 
      {
        console.log(`postInit -- getMemberTicketInfo -- error --> ${error}`)
      });
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
        const control = form.get(key);
        const errors = (control instanceof FormGroup || control instanceof FormArray)
            ? this.getAllErrors(control)
            : control.errors;
        if (errors) {
            acc[key] = errors;
            hasError = true;
        }
        return acc;
    }, {} as { [key: string]: any; });
    return hasError ? result : null;
}

  onSubmit() {
    console.log(this.firstUserSignupFormGroup.value);

    let postCreateContractorRequest: postCreateContractorRequest = { ... this.firstUserSignupFormGroup.value };

    this.service.createContractorUser(postCreateContractorRequest).subscribe( () => 
    {
      this.router.navigate(['/signup/succes']);
    },
    e => {

      this.router.navigate(['/signup/failed']);

      console.log(e);
    })
    
    
  }
}

